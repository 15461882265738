import { createRootFromTemplate } from '../domHelpers'
import accordionItemSection from './accordionItem'

export default function accordionSection({ parent, attributes, accordionHeader, accordionCTA, accordionCTACallback }) {
  const root = createRootFromTemplate('section-accordion')

  const header = root.querySelector('#header')
  header.innerText = accordionHeader

  const headerCTA = root.querySelector('#headerCTA')
  const header_cta_text = root.querySelector('#header_cta_text')

  if (accordionCTA && accordionCTACallback) {
    header_cta_text.innerText = accordionCTA
    headerCTA.addEventListener('click', accordionCTACallback)
  } else {
    headerCTA.remove()
  }

  const itemsRoot = root.querySelector('#items')

  attributes.forEach((item) => {
    accordionItemSection({ parent: itemsRoot, attributes: item })
  })

  parent.appendChild(root)
}
