import { createRootFromTemplate } from '../domHelpers'
import Router from '../router'

export default function homePage({ parent, attributes }) {
  const root = createRootFromTemplate('page-home')

  const header = root.querySelector('#header')
  header.innerText = attributes.title

  const languageSelector = root.querySelector('#languageSelector')

  let initialLanguage = localStorage.getItem('selectedLanguage')
  if (!(initialLanguage && attributes.need_some_help.find(({ language }) => language === initialLanguage))) {
    initialLanguage = attributes.need_some_help[0].language
  }

  setNeedSomeHelp({ root, attributes, language: initialLanguage })

  attributes.need_some_help.forEach((item) => {
    const option = document.createElement('option')
    option.setAttribute('value', item.language)
    if (item.language === initialLanguage) {
      option.setAttribute('selected', 'selected')
    }
    option.innerText = item.language
    languageSelector.appendChild(option)
  })

  languageSelector.addEventListener('change', (event) => {
    setNeedSomeHelp({ root: document, attributes, language: event.target.value })
  })

  const contactCTA = root.querySelector('#contactCTA')
  const contactCTAText = root.querySelector('#contactCTAText')
  contactCTAText.innerText = attributes.contact_button_cta

  contactCTA.addEventListener('click', () => {
    Router.visit('fileAReport')
  })
  const spotTheSignsCTA = root.querySelector('#spotTheSignsCTA')
  const spotTheSignsCTAText = root.querySelector('#spotTheSignsCTAText')
  spotTheSignsCTAText.innerText = attributes.spot_the_signs_cta

  spotTheSignsCTA.addEventListener('click', () => {
    Router.visit('spotTheSigns')
  })


  // donate button

  const donateCTAText = root.querySelector('#donateCTAText')
  donateCTAText.innerText = attributes.donate_cta

  const donateCTA = root.querySelector('#donateCTA')
  donateCTA.addEventListener('click', () => {
    Router.visit('donate')
  })


  parent.appendChild(root)
}

function setNeedSomeHelp({ root, language, attributes }) {
  localStorage.setItem('selectedLanguage', language)

  const selectedLanguageData = attributes.need_some_help.find((item) => item.language === language)
  const backupLanguageData = attributes.need_some_help[0]

  let content = [
    selectedLanguageData.need_some_help_title,
    selectedLanguageData.need_some_help_body,
    selectedLanguageData.need_some_help_cta,
    selectedLanguageData.need_some_help_number
  ]

  let backupContent = [
    backupLanguageData.need_some_help_title,
    backupLanguageData.need_some_help_body,
    backupLanguageData.need_some_help_cta,
    backupLanguageData.need_some_help_number
  ]

  // fill in empty fields with English
  content.forEach((value, i) => {
    if (!(value && value.trim().length > 0)) {
      content[i] = backupContent[i]
    }
  })

  // fill content into page
  const needSomeHelpHeader = root.querySelector('#needSomeHelpHeader')
  const needSomeHelpBody = root.querySelector('#needSomeHelpBody')
  const callCTA = root.querySelector('#needSomeHelpCTA')
  const callNumber = root.querySelector('#needSomeHelpNumber')

  needSomeHelpHeader.innerText = content[0]
  needSomeHelpBody.innerHTML = content[1]
  callCTA.innerText = content[2]
  callNumber.innerText = content[3]
  callNumber.setAttribute('href', `tel:${content[3].replaceAll(' ', '')}`);
}
