export default function setupSidebar() {
  const sidebar = document.querySelector('#sidebar')
  const sidebarToggle = document.querySelector('#sidebar-toggle')
  const sidebarItems = document.querySelector('#sidebar-items')
  const sidebarOverlay = document.querySelector('.sidebar-overlay')

  const openingDelay = 250

  sidebarToggle.addEventListener('click', () => {
    sidebar.classList.toggle('open')

    document.body.classList.toggle('sidebar-open')

    if (sidebarOverlay.classList.contains('hidden')) {
      setTimeout(function() {
        sidebarOverlay.classList.toggle('hidden')
      }, openingDelay)
    } else {
      sidebarOverlay.classList.toggle('hidden')
    }
  })

  sidebarOverlay.addEventListener('click', () => {
    sidebar.classList.remove('open')
    sidebarOverlay.classList.add('hidden')
    document.body.classList.remove('sidebar-open')
  })

  sidebarItems.addEventListener('click', (event) => {
    event.stopPropagation()
  })

  Object.keys(Router.pageNames).forEach((page) => {
    const button = document.createElement('button')
    button.innerText = Router.pageNames[page]
    button.addEventListener('click', () => {
      Router.visit(page)

      sidebar.classList.remove('open')
      sidebarOverlay.classList.add('hidden')
      document.body.classList.remove('sidebar-open')
    })
    sidebarItems.appendChild(button)
  })
}
