import { createRootFromTemplate } from '../domHelpers'

export default function fileAReportSection({ parent, attributes }) {
  const root = createRootFromTemplate('page-fileAReport')

  const header = root.querySelector('#header')
  header.innerText = attributes.title

  const canContactReport = root.querySelector('#report_can_contact')
  const contactDetails = root.querySelector('#contact-details')
  const numberField = root.querySelector('#report_phone_number')
  const emailField = root.querySelector('#report_email_address')

  canContactReport.addEventListener('change', (event) => {
    if (event.target.value === 'yes') {
      contactDetails.classList.remove('hidden')
    } else {
      contactDetails.classList.add('hidden')
      numberField.value = '' // Empty contents of input field if the user then selects no
      emailField.value = '' // Empty contents of input field if the user then selects no
    }
  })

  const form = root.querySelector('form')
  const formStatus = root.querySelector('#formStatus')
  const formStatusMessage = root.querySelector('#formStatusMessage')

  const submitButton = root.querySelector('#submit-button')

  function submissionFailed() {
    formStatus.classList.remove('hidden')
    formStatusMessage.innerText = 'Sorry, something went wrong, please try again later'

    submitButton.disabled = false

    Router.root.scroll({ top: 0, behavior: 'smooth' })
  }

  form.addEventListener('submit', (event) => {
    event.preventDefault()

    submitButton.disabled = true

    if (!event.target.checkValidity()) {
      formStatus.classList.remove('hidden')
      formStatusMessage.innerText = 'Please fill in all required fields'

      submitButton.disabled = false

      Router.root.scroll({ top: 0, behavior: 'smooth' })

      return
    }

    const body = {}

    const formData = new FormData(event.target)
    for (const [key, value] of formData) {
      // This regex takes a string like 'foo[bar]' and returns 'bar'
      const formattedKey = key.replace(/.*\[(.*)\]/, '$1')

      body[formattedKey] = value
    }

    delete body['authenticity_token']
    body['action'] = 'sendform'

    fetch('https://www.modernslaveryhelpline.org/report/go', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(body).toString()
    }).then((response) => {
      if (response.ok) {
        response.json().then((body) => {
          if (body.result) {
            Router.visit('submissionSuccessful')
          } else {
            rollbar.error(`Form submission failed with message: ${body.message}`)
            submissionFailed()
          }
        })
      } else {
        rollbar.error('Form submission failed')
        submissionFailed()
      }
    }).catch((e) => {
      rollbar.error(`Form submission failed with error ${e}`)
      submissionFailed()
    })
  })

  parent.appendChild(root)
}
