import Router from './router'

const apiHost = document.querySelector('meta[name="cms-host-url"]').content
const clientVersion = document.querySelector('meta[name="client-version"]').content

export default class Content {
  static async fetchContent() {
    try {
      const response = await fetch(
        `${apiHost}/mobile/cms_content?client_version=${clientVersion}`,
        {
          headers: {
            'Accept': 'application/json',
          }
        }
      )

      if (!response.ok) return

      const json = await response.json()

      Content.content = json
    } catch (e) {
      console.error(e)
    }
  }

  static get content() {
    if (this._content) return this._content

    let localStorageContent = localStorage.getItem('content')

    if (localStorageContent) {
      this._content = JSON.parse(localStorageContent)

      return this._content
    } else if (window.mobileDefaultContent) {
      Content.content = window.mobileDefaultContent

      return this._content
    } else {
      console.error('No content found')
    }
  }

  static set content(value) {
    this._content = value

    localStorage.setItem('content', JSON.stringify(value))
  }
}

window.Content = Content
