import Rollbar from 'rollbar'

window.rollbar = new Rollbar({
  accessToken: 'bdf7498caa6c418f80f146a6d4786448',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: inRails ? 'rails' : 'cordova',
    client: {
      javascript: {
        code_version: '1.0',
        // source_map_enabled: true,
        // guess_uncaught_frames: true
      }
    }
  }
})

import './controllers/application'
import './controllers/shared'
import tippy from 'tippy.js'
import Swal from 'sweetalert2'

import './icons'

import Router from './mobile/router'
import Content from './mobile/content'

import homePage from './mobile/pages/home'
import spotTheSignsPage from './mobile/pages/spotTheSigns'
import spotTheSignsVideoPage from './mobile/pages/spotTheSignsVideo'
import whatIsModernSlaveryPage from './mobile/pages/whatIsModernSlavery'
import complyWithUKLawPage from './mobile/pages/complyWithUKLaw'
import aboutAppPage from './mobile/pages/aboutApp'
import fileAReportPage from './mobile/pages/fileAReport'
import donatePage from './mobile/pages/donate'
import submissionSuccessfulPage from './mobile/pages/submissionSuccessful'

import setupSidebar from './mobile/sidebar'

Router.pages = {
  home: homePage,
  spotTheSigns: spotTheSignsPage,
  spotTheSignsVideo: spotTheSignsVideoPage,
  whatIsModernSlavery: whatIsModernSlaveryPage,
  complyWithUKLaw: complyWithUKLawPage,
  aboutApp: aboutAppPage,
  donate: donatePage,
  fileAReport: fileAReportPage,
  submissionSuccessful: submissionSuccessfulPage
}

Router.pageNames = {
  home: 'Home',
  spotTheSigns: 'Spot the signs',
  whatIsModernSlavery: 'What is modern slavery?',
  complyWithUKLaw: 'Comply with UK law',
  aboutApp: 'About the app',
  fileAReport: 'File a report',
  donate: 'Donate now'
}

window.addEventListener('popstate', () => {
  const page = window.location.hash == '' ? 'home' : window.location.hash.slice(1)
  Router.visit(page, { pushState: false })
})

const startupEvent = inRails ? 'DOMContentLoaded' : 'deviceready'

document.addEventListener(startupEvent, () => {
  document.addEventListener('backbutton', (e) => {
    e.preventDefault()
    if (window.location.hash === '') {
      navigator.app.exitApp()
    } else {
      window.history.back()
    }
  }, false)

  Router.root = document.querySelector('#root')

  setupSidebar()

  const homeCTA = document.querySelector('#homeCTA')
  homeCTA.addEventListener('click', () => {
    Router.visit('home')
  })

  const page = window.location.hash.slice(1) || 'home'

  if (Content.content) {
    Router.visit(page, { pushState: false })
  }

  Content.fetchContent().then(() => {
    Router.visit(page, { pushState: false })
  })

  if (window.StatusBar) {
    StatusBar.overlaysWebView(true)
    if (cordova.platformId == 'android') {
      StatusBar.overlaysWebView(false)
    } else {
      StatusBar.overlaysWebView(true)
    }
  }

  if (window.StatusBarHeight) {
    setCssStatusBarHeight()

    window.addEventListener('resize', () => {
      setCssStatusBarHeight()
    })
  }
})

function setCssStatusBarHeight() {
  StatusBarHeight.getValue((height) => {
    document.querySelector(':root').style.setProperty('--status-bar-height', `${height}px`)
  })
}
