import { createRootFromTemplate } from '../domHelpers'
import Router from '../router'

import accordionSection from '../sections/accordion'

export default function spotTheSignsPage({ parent, attributes }) {
  const root = createRootFromTemplate('page-spotTheSigns')

  const header = root.querySelector('#header')
  header.innerText = attributes.title

  const body = root.querySelector('#body')
  body.innerHTML = attributes.body

  const accordionRoot = root.querySelector('#accordion')
  accordionSection({ parent: accordionRoot, attributes: attributes.accordion, accordionHeader: attributes.accordion_title, accordionCTA: attributes.accordion_cta, accordionCTACallback: accordionCTACallback })

  parent.appendChild(root)
}

function accordionCTACallback() {
  Router.visit('spotTheSignsVideo')
}
