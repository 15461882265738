import { createRootFromTemplate } from '../domHelpers'

import accordionSection from '../sections/accordion'
import carouselSection from '../sections/carousel'

export default function whatIsModernSlaveryPage({ parent, attributes }) {
  const root = createRootFromTemplate('page-whatIsModernSlavery')

  const header = root.querySelector('#header')
  header.innerText = attributes.title

  if (attributes.carousel.length > 0) {
    const carouselRoot = root.querySelector('#carousel')
    carouselSection({ parent: carouselRoot, attributes: attributes.carousel })
  }

  const accordionRoot = root.querySelector('#accordion')
  accordionSection({ parent: accordionRoot, attributes: attributes.accordion, accordionHeader: attributes.accordion_title })

  parent.appendChild(root)
}
