import Glide from '@glidejs/glide'

import { createRootFromTemplate } from '../domHelpers'

import carouselItemSection from './carouselItem'
import carouselDotSection from './carouselDot'

export default function carouselSection({ parent, attributes }) {
  const root = createRootFromTemplate('section-carousel')

  const itemsRoot = root.querySelector('#items')
  const dotsRoot = root.querySelector('#bullets')

  attributes.forEach((item, i) => {
    carouselItemSection({ parent: itemsRoot, url: item })
    carouselDotSection({ parent: dotsRoot, url: '=' + i })
  })

  const glide = root.querySelector('.glide')

  const activeBullet = 'glide__bullet--active'
  const bulletsContainer = glide.querySelector('.glide__bullets')

  const api = new Glide(glide, {
    type: 'carousel',
    autoplay: 2000,
    animationDuration: 1000
  }).mount()

  function pageLoadHander () {
    api.update()

    document.removeEventListener('page:load', pageLoadHander)
  }

  api.on('run', () => {
    requestAnimationFrame(() => {
      const glideDir = bulletsContainer.querySelector(`[data-glide-dir="=${api.index}"]`)

      bulletsContainer.querySelector(`.${activeBullet}`).classList.remove(activeBullet)

      if (glideDir) glideDir.classList.add(activeBullet)
    })
  })

  document.addEventListener('page:load', pageLoadHander)
  parent.appendChild(root)
}
