import { createRootFromTemplate } from '../domHelpers'
import Router from '../router'

export default function aboutAppPage({ parent, attributes }) {
  const root = createRootFromTemplate('page-spotTheSignsVideo')

  const link = root.querySelector('#spotTheSignsLink')

  link.addEventListener('click', () => {
    Router.visit('spotTheSigns')
  })

  parent.appendChild(root)
}
