import { Application } from "@hotwired/stimulus";
import AccordionController from "./../../../components/accordion_component/accordion_controller";
import TabsController from "./../../../components/tabs_component/tabs_controller";

const application = Application.start();

// Configure Stimulus development experience
application.warnings = true;
application.debug = false;
window.Stimulus = application;

Stimulus.register("accordion", AccordionController);
Stimulus.register("tabs", TabsController);

export { application };
