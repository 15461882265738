import { createRootFromTemplate } from '../domHelpers'

export default function aboutAppPage({ parent, attributes }) {
  const root = createRootFromTemplate('page-aboutApp')

  const header = root.querySelector('#header')
  header.innerText = attributes.title

  const body = root.querySelector('#body')
  body.innerHTML = attributes.body

  parent.appendChild(root)
}
