import { createRootFromTemplate } from '../domHelpers'

export default function carouselItemSection({ parent, url }) {
  const root = createRootFromTemplate('section-carouselItem')

  const image = root.querySelector('#image')
  image.src = url

  parent.appendChild(root)
}
