import { createRootFromTemplate } from '../domHelpers'

export default function accordionItemSection({ parent, attributes }) {
  const root = createRootFromTemplate('section-accordionItem')

  const clickable = root.querySelector('.accordion-title')
  const title = root.querySelector('.accordion-title')
  const content = root.querySelector('.accordion-content')

  clickable.addEventListener('click', () => {
    if (!content.classList.contains('active')) {
      content.classList.add('active')
      content.style.height = 'auto'

      const height = content.clientHeight + 'px'

      content.style.height = '0px'

      setTimeout(function () {
        content.style.height = height
      }, 0)
    } else {
      content.style.height = '0px'

      content.addEventListener('transitionend', function () {
        content.classList.remove('active')
      }, {
        once: true
      })
    }
    title.classList.toggle('open')
  })

  const titleText = root.querySelector('#title')
  titleText.innerText = attributes.accordion_item_title

  const body = root.querySelector('.accordion-content')
  body.innerHTML = attributes.accordion_item_body

  parent.appendChild(root)
}

