import { createRootFromTemplate } from '../domHelpers'

export default function carouselDotSection({ parent, url }) {
  const root = createRootFromTemplate('section-carouselDot')

  const bullet = root.querySelector('#bullet')
  bullet.dataset.glideDir = url

  parent.appendChild(root)
}
