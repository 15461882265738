import { createRootFromTemplate } from '../domHelpers'

export default function submissionSuccessful({ parent, attributes }) {
  const root = createRootFromTemplate('page-submissionSuccessful')

  const header = root.querySelector('#header')
  header.innerText = attributes.title

  const body = root.querySelector('#body')
  body.innerHTML = attributes.body

  const homeButton = root.querySelector('#home-button')
  homeButton.addEventListener('click', () => {
    Router.visit('home')
  })

  parent.appendChild(root)
}
