import { createRootFromTemplate } from '../domHelpers'
import Router from '../router'

export default function donatePage({ parent, attributes }) {
  const root = createRootFromTemplate('page-donate')

  const header = root.querySelector('#header')
  header.innerText = attributes.title

  const body = root.querySelector('#body')
  body.innerHTML = attributes.body

  const donateButton = root.querySelector('#donateButton')
  donateButton.innerText = attributes.donate_cta
  donateButton.href = attributes.donate_cta_link

  parent.appendChild(root)
}
