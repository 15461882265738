import { Controller } from '@hotwired/stimulus';
import tippy, { sticky } from 'tippy.js';

export default class extends Controller {

  tippyEl = null;

  connect() {
    this.tippyEl = tippy(this.element, {
      trigger: 'mouseenter focus click',
      interactiveBorder: 10,
      content: this.element.dataset.content || this.element.title,
      allowHTML: true,
      interactive: true,
      sticky: true,
      plugins: [sticky],
    });
  }

  disconnect() {
    this.tippyEl.destroy();
  }

}