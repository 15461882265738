import Content from './content'

export default class Router {
  static pages = {}
  static pageNames = {}

  // This should be set when the dom is loaded
  static root = null

  static visit(page, options = { pushState: true }) {
    if (options.pushState) {
      window.history.pushState({}, page, `#${page}`)
    }

    while (Router.root.firstChild) {
      Router.root.removeChild(this.root.firstChild)
    }

    document.body.setAttribute('class', page)

    Router.pages[page]({ parent: Router.root, attributes: Content.content[page] })

    const event = new CustomEvent('page:load')
    document.dispatchEvent(event)

    this.root.scroll({ top: 0, behavior: 'instant' })

    if (window.StatusBar) {
      if(document.body.classList.contains('home')) {
        StatusBar.styleLightContent();
      } else {
        StatusBar.styleDefault();
      }
    }
  }
}

window.Router = Router
